import React, { useEffect } from "react";
import App from "../components/App";
import Text from "../elements/Text";
import {
  documentToReactComponents,
  Options
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Box from "../elements/Box";
import Link from "../elements/Link";
import colors from "../styles/colors";
import Arrow from "../components/Arrow";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { ClassNames } from "@emotion/react";
import { addMediaQuery, breakPoints } from "../styles/responsive";
import ButtonLink from "../elements/ButtonLink";
import SEO from "../components/SEO";
import AnimatedBox from "../elements/AnimatedBox";
import { useInView } from "react-intersection-observer";
import ViewportWrapper from "../components/ViewportWrapper";

interface PageContextProps {
  author: string;
  title: string;
  date: string;
  content: {
    raw: string;
    references: {
      contentful_id: string;
      description: string;
    }[];
  };
  coverImage: {
    gatsbyImageData: IGatsbyImageData;
  };
  categories: string[];
}

interface BlogPostsProps {
  pageContext: PageContextProps;
  path: string;
  location: {
    pathname: string;
  };
}

const BlogPosts = (props: BlogPostsProps) => {
  const { pageContext, path } = props;
  const { author, title, content, date, coverImage, categories } = pageContext;
  const assetBlockMap = new Map();

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      pageCategory: title
    });
  }, [title]);

  for (const asset of content.references) {
    assetBlockMap.set(asset.contentful_id, asset);
  }
  const options: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text lineHeight="26px" fontSize="18px" color={colors.grey}>
          {children}
        </Text>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Text
            as="a"
            href={node.data.uri}
            target="_blank"
            color={colors.mediumLime}
            hoverUnderline
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const asset = assetBlockMap.get(node.data.target.sys.id);
        return (
          <img
            style={{ maxWidth: "100%", margin: "auto" }}
            src={`https://${asset.file.url}`}
            // height={asset.file.details.image.height}
            // width={asset.file.details.image.width}
            alt={asset.description}
          />
        );
      }
    }
  };

  const richContent = documentToReactComponents(
    JSON.parse(content.raw),
    options
  );

  return (
    <App>
      <SEO url={path} title={`${title} - acidgreen`} />
      <Box
        backgroundColor={colors.mediumLime}
        height="44px"
        display="flex"
        alignItems="center"
      >
        <Link
          to="/blog"
          color={colors.lightDarkGrey}
          fontSize="14px"
          lineHeight="16px"
          hoverNormal
          hoverUnderline
        >
          <Arrow
            width="1rem"
            margin={["0 1rem", "0 1rem", "0 1rem 0 3rem"]}
            transform="rotate(90deg)"
            color={colors.lightDarkGrey}
          />
          Back to all Articles
        </Link>
      </Box>
      <ViewportWrapper ref={ref}>
        <Box
          display="flex"
          flexDirection="column"
          maxWidth="1024px"
          width={["90%", "90%", "80%"]}
          position="relative"
          margin="auto"
        >
          <AnimatedBox doesAnimate={inView} animateDown>
            <ClassNames>
              {({ css }) => (
                <GatsbyImage
                  image={coverImage?.gatsbyImageData}
                  alt={title ?? ""}
                  objectFit="cover"
                  className={css(imageStyles)}
                />
              )}
            </ClassNames>
          </AnimatedBox>
          <Box
            backgroundColor={colors.white}
            maxWidth="926px"
            width={["90%", "90%", "90%%", "90%", "100%"]}
            margin={[
              "5rem auto 0",
              "5rem auto 0",
              "10rem auto 0",
              "12rem auto 0",
              "12rem auto 0"
            ]}
            zIndex={2}
          >
            <Box
              width="90%"
              margin="auto"
              padding="2.5rem 0"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text
                color={colors.greySixty}
                fontSize="14px"
                lineHeight="16px"
                margin="0"
              >
                {date}
              </Text>
              <Text
                as="h2"
                fontSize={["32px", "32px", "38px"]}
                lineHeight="42px"
                textAlign="center"
                margin={["1rem auto 2.5rem"]}
              >
                {title}
              </Text>
              <Text color={colors.grey} margin="0 0 2.5rem">
                {author}
              </Text>
              <Box display="flex">
                {categories?.map((category, index) => (
                  <Text
                    key={index}
                    backgroundColor={colors.mediumLime}
                    borderRadius="6px"
                    padding="6px 1rem"
                    color={colors.lightDarkGrey}
                    margin={index !== 0 ? "0 0 0 0.75rem" : 0}
                  >
                    {category}
                  </Text>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="720px"
            width={["75%", "75%", "75%", "75%", "100%"]}
            margin="auto"
            backgroundColor="white"
          >
            {richContent}
          </Box>
          <ButtonLink to="/blog" variant="primary" margin="auto">
            View all articles
          </ButtonLink>
        </Box>
        <Box marginTop="5rem" backgroundColor={colors.mediumLime} width="100%">
          <Box
            padding={["40px 24px", "40px 24px", "32px"]}
            margin="auto"
            display="flex"
            flexDirection={["column", "column", "row"]}
            alignItems="center"
            justifyContent="center"
          >
            <Text
              fontSize="32px"
              lineHeight="42px"
              margin={["0 0 32px", "0 0 32px", "0 32px 0 0"]}
              fontWeight="bold"
            >
              Need help setting up your e-commerce website?
            </Text>
            <ButtonLink
              variant="secondary"
              to="/contact-us"
              textAlign="center"
              width={["100%", "100%", "auto"]}
            >
              Contact Us
            </ButtonLink>
          </Box>
        </Box>
      </ViewportWrapper>
    </App>
  );
};

export default BlogPosts;

const imageStyles = `
position: absolute;
width: 100%;
display: flex;
margin: 1rem auto 0;
z-index: -1;
height: 250px;
${addMediaQuery(breakPoints.tablet, `height: 350px;`)}
${addMediaQuery(
  breakPoints.tabletLandscape,
  `
  margin-top: 2.5rem;
`
)}

`;
