import styled from "@emotion/styled";
import animation from "../../styles/animation";
import Box, { BoxProps } from "../Box/Box";

interface AnimatedBoxProps extends BoxProps {
  doesAnimate: boolean;
  delay?: string;
  animateRight?: boolean;
  animateLeft?: boolean;
  animateDown?: boolean;
}

const AnimatedBox = styled(Box)<AnimatedBoxProps>`
  transform: ${({ doesAnimate, animateRight, animateLeft, animateDown }) =>
    doesAnimate
      ? "translate3d(0,0,0)"
      : animateDown
      ? "translate3d(0, -20px, 0)"
      : animateRight
      ? "translate3d(-20px, 0, 0)"
      : animateLeft
      ? "translate3d(20px, 0, 0)"
      : "translate3d(0, 20px, 0)"};
  opacity: ${({ doesAnimate }) => (doesAnimate ? "1" : "0")};
  transition: ${({ doesAnimate }) =>
    doesAnimate ? `all 1s ${animation.transition}` : ""};
  transition-delay: ${({ delay }) => delay ?? ""};
`;

export default AnimatedBox;
