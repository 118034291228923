import styled from "@emotion/styled";
import { color, flexbox, layout, space, variant } from "styled-system";
import { ButtonProps, ButtonVariant } from "../Button/Button";
import Link from "../Link";

const ButtonLink = styled(Link)<ButtonProps>(
  variant(ButtonVariant),
  space,
  layout,
  flexbox,
  color,
  `box-sizing: border-box;
  white-space: pre;
  `
);

export default ButtonLink;
